<template>
<div class="table">
  <el-table
    ref="multipleTable"
    :data="tableData"
    :highlight-current-row="true"
    tooltip-effect="dark"
    style="width:100%;color:#858585;"
    @selection-change="selectRow"
    :header-cell-style="tableHeaderCellStyle">
    <el-table-column
     type="selection"
     fill="#00CCCC"
     fixed="left">
    </el-table-column>
    <!-- 表头开始 -->
    <el-table-column
      prop="examineName"
      label="姓名"
      align="center">
    </el-table-column>
    <el-table-column
      prop="gender"
      label="性别"
      width="90px"
      align="center">
    </el-table-column>
    <el-table-column
      prop="age"
      label="年龄"
      align="center"
      width="90px"
      show-overflow-tooltip>
    </el-table-column>
    <el-table-column
      prop="cardId"
      label="身份证号"
      align="center"
      show-overflow-tooltip>
    </el-table-column>
    <el-table-column
      prop="mobile"
      label="手机号"
      align="center"
      show-overflow-tooltip>
    </el-table-column>
    <el-table-column
      prop="id"
      label="报告编号"
      align="center"
      show-overflow-tooltip>
    </el-table-column>
    <el-table-column
      prop="createTime"
      align="center"
      label="上传时间"
      show-overflow-tooltip>
    </el-table-column>
    <el-table-column
      prop="resultRisk"
      label="评估结果"
      align="center"
      show-overflow-tooltip>
    </el-table-column>
     <el-table-column
      align="center"
      label="报告情况">
       <template slot-scope="scope">
        <span style="color:#FFBD3D" v-if="scope.row.status!='已处理'">{{scope.row.status}}</span>
        <span style="color:#00D085" v-if="scope.row.status==='已处理'">{{scope.row.status}}</span>
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      label="操作"
      width="120">
      <template slot-scope="scope">
        <span style="color:#00cccc;cursor: pointer;" @click="clickRow(scope.row)">查看详情</span>
      </template>
    </el-table-column>
  </el-table>
  <!-- 分页 -->
  <div class="page">
  <!-- left -->
  <span class="pageLeft">共 {{count}} 条记录 第 {{currentPage}} / {{tatalPage}}页</span>
  <!-- right -->
  <el-pagination
      @size-change="SizeChange"
      background
      @current-change="CurrentChange"
      @prev-click="prevClick"
      @next-click="nextClick"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="onlyPage"
      :pager-count="5"
      layout="sizes, prev, pager, next, jumper"
      :total="count">
    </el-pagination>
  </div>
</div>
</template>

<script>
import {getMajorList} from '@/api/userManage.js'
  export default {
    data() {
      return {
        tableData: [],//表格数据
        multipleSelection: [],// tableCheckboxValue
        // 分页相关
        currentPage:1,// 当前页面,默认1
        tatalPage:0,//总页数,为加载时先为0，页面首次加载后去请求即可
        count:0,//总的条数记录
        onlyPage:10,// 当前页面可以展示多少条数据,默认10 
      }
    },
  
     mounted(){
        this.getData(1,10,null,null,null,null,null); 
     },
    
    //监听勾选值
    watch:{
        multipleSelection:{
            handler(pre){
              var tem = pre;
              if(tem.length==0){
                this.$parent.isSelect = true;
              }
              if(tem.length>0){
                this.$parent.isSelect = false;
                this.$parent.tableSelect = tem ;
               }

            },
            deep:true,
            immediate:true
        }
    },
   
    methods: {
      //表头样式回调
      tableHeaderCellStyle(){
          return "color:#fff;background-color:#00CCCC";
      },
      // getData
      getData(currentPage,onlyPage,status,selectResult,selectAll,startDate,endDate){ 
              const rLoading = this.openLoading();// 开始loading
              getMajorList({
              currentPage: currentPage,
              pageSize: onlyPage,
              status:status,
              resultRisk:selectResult,
              keyWord:selectAll,
              startDate:startDate,
              endDate:endDate
            }).then((res)=>{
              if(res.data.status == 200){
                this.tableData = res.data.data.records;
                this.tatalPage =parseInt(res.data.data.size) ;
                this.count =parseInt(res.data.data.total);
                rLoading.close() ;// 关闭loading
              }else{
                this.$message.error(res.data.message);
              }
            }).catch(res=>{
               this.$message.error(res);
            })
      },
      // 复选框选项
      selectRow(val) {
        this.multipleSelection = val;
      },
      // 分页相关
      SizeChange(val){
        this.onlyPage = val;
        this.getData(this.currentPage,this.onlyPage,this.$parent.isActiveStatus,this.$parent.evaValue,this.$parent.searchOption,this.$parent.seachDate[0],this.$parent.seachDate[1]);
      },
      CurrentChange(val){
        this.currentPage = val;
        this.getData(this.currentPage,this.onlyPage,this.$parent.isActiveStatus,this.$parent.evaValue,this.$parent.searchOption,this.$parent.seachDate[0],this.$parent.seachDate[1]);
      },
      prevClick(val){
        // 上一页
        // console.log("上一页的页数是",val);
        this.currentPage = val ;
        this.getData(this.currentPage,this.onlyPage,this.$parent.isActiveStatus,this.$parent.evaValue,this.$parent.searchOption,this.$parent.seachDate[0],this.$parent.seachDate[1]);
      },
      nextClick(val){
        this.currentPage = val ;
        this.getData(this.currentPage,this.onlyPage,this.$parent.isActiveStatus,this.$parent.evaValue,this.$parent.searchOption,this.$parent.seachDate[0],this.$parent.seachDate[1]);
      },
      //单击表格行
      clickRow(e){
        this.$router.push({
          path:'/majorDetails',
            query:{
                id:e.id,
                rid:e.reportInfoId,
              }
          })
      }
    },
  }
</script>
<style scoped>
.table{
    margin-top: .1563rem;
}
.el-button{
    color: #00A3A3 !important;
}
.action{
    display: flex;
    flex-direction: column;
}
.actionP{
  cursor: pointer;
  color: #00CCCC;
  margin: 0 !important;
}
.resoult{
  text-align: left;
}
.page{
  display: flex;
  margin-top: .3125rem;
  justify-content: space-between;
  align-items: center;
}
.pageLeft{
  color: #858585;
  font-size: .1094rem;
  line-height: .1875rem;
}
.el-pagination{
  color: #858585 !important;
  font-size: .1094rem !important;
  line-height: .1875rem !important;
}
</style>